import React from 'react'

import IconDuck from '../images/icon-bar-duck.svg'
import IconGlass from '../images/icon-bar-glass.svg'
import IconAladin from '../images/icon-bar-aladin.svg'
import IconFlamingo from '../images/icon-bar-flamingo.svg'
import IconMogo from '../images/icon-bar-glass-mogo.svg'
import IconCosmo from '../images/icon-bar-glass-cosmo.svg'
import IconBoxAsia from '../images/icon-bar-box-asia.svg'
import IconGlassPanda from '../images/icon-bar-glass-panda.svg'
import IconPear from '../images/icon-bar-pear.svg'

const IconSvg = ({ iconName, alt }) => {
  return (
    <>
      {iconName === 'duck' && <img src={IconDuck} alt={alt} />}
      {iconName === 'glass' && <img src={IconGlass} alt={alt} className="bar-icon-glass max-h-full m-auto" />}
      {iconName === 'aladin' && <img src={IconAladin} alt={alt} className="bar-icon-aladin max-h-full m-auto" />}
      {iconName === 'flamingo' && <img src={IconFlamingo} alt={alt} className="bar-icon-flamingo max-h-full m-auto" />}
      {iconName === 'glass-mogo' && <img src={IconMogo} alt={alt} className="bar-icon-mogo max-h-full m-auto" />}
      {iconName === 'glass-cosmo' && <img src={IconCosmo} alt={alt} className="bar-icon-cosmo max-h-full m-auto" />}
      {iconName === 'box-asia' && <img src={IconBoxAsia} alt={alt} className="bar-icon-box-asia max-h-full m-auto" />}
      {iconName === 'pear' && <img src={IconPear} alt={alt} className="bar-icon-pear max-h-full m-auto" />}
      {iconName === 'glass-panda' && (
        <img src={IconGlassPanda} alt={alt} className="bar-icon-glass-panda max-h-full m-auto" />
      )}
    </>
  )
}

export default IconSvg
